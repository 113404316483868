import axios from 'axios';
import store from '../store';
import moment from 'moment';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API + "/api/v1",
  timeout: 15000
});

if (sessionStorage.getItem('token')) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
  instance.defaults.headers.common["Content-Type"] = "application/json";
}
instance.defaults.headers.common["createdAt"] = moment().format('YYYY-MM-DD HH:mm:ss');

// Token refresh function
async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = sessionStorage.getItem('token');
    const response = await axios.post(
      `${process.env.VUE_APP_API}/api/v1/refresh-token`, 
        { refresh_token: refreshToken },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
    );
    if (response) {
      const newAccessToken = response.data.data.token;
      const newRefreshToken = response.data.data.refresh_token;
      sessionStorage.setItem('token', newAccessToken);
      sessionStorage.setItem('refresh_token', newRefreshToken);
      localStorage.setItem('token', newAccessToken);
      localStorage.setItem('refresh_token', newRefreshToken);
      instance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
      return newAccessToken;
    }
  } catch (error) {
    console.error('Unable to refresh token', error);
    throw error;
  }
}

instance.interceptors.request.use(function(config) {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const errorCode = error.response.data.error_code;
    const notBrowserErrorMessages = [
      "The username is incorrect. Please try again.", 
      "The password is incorrect. Please try again.", 
      "Username or password is incorrect. Please try again.", 
      "Account is blocked due to multiple failed login attempts. Please contact your administrator!."
    ];

    if (error.response && sessionStorage.getItem('token') && errorCode === 'error_086') {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      window.location.href = '/login';
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry && sessionStorage.getItem('token') && errorCode === 'error_106') {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return instance(originalRequest); // Re-make the request with the new token
      } catch (refreshError) {
        store.commit('setErrorBrowser', 'Need to login again');
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        // Redirect users to the login page if needed
      }
    }

    if (error.response && error.response.status === 401 && !notBrowserErrorMessages.includes(error.response.data.message) && errorCode === 'error_107') {
      const errorBrowser = error.response.data.message;
      store.commit('setErrorBrowser', errorBrowser);
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
    }
    // return error;
    return Promise.reject(error);
  }
);

export default {
  instance,
  get(url, params) {
    return instance.get(url, params);
  },
  post(url, params) {
    return instance.post(url, params);
  },
  put(url, params) {
    return instance.put(url, params);
  },
  delete(url, params) {
    return instance.delete(url, {data: JSON.stringify(params)});
  }
}